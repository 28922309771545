* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.heading {
  font-size: 2.5rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.full {
  margin-top: 15vh;
}

.img1 {
  display: block;
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cardswrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 90%;
  margin: auto;
}

.card {
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  -webkit-perspective: 100rem;
  perspective: 100rem;
  height: 316px;
}

.card:hover .cardfront {
  box-shadow: 0rem 0rem 1rem rgba(255, 255, 255, 0.1);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  visibility: hidden;
  opacity: 0;
}

.card:hover .cardback {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  visibility: visible;
  opacity: 1;
}

.card:hover .cardback-content {
  -webkit-animation: translate 1s ease-in-out;
  animation: translate 1s ease-in-out;
}

.cardfront {
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0 1rem rgba(51, 51, 51, 0.1),
    0 0 1rem rgba(51, 51, 51, 0.1);
  box-shadow: 0 0 1rem rgba(51, 51, 51, 0.1), 0 0 1rem rgba(51, 51, 51, 0.1);
  overflow: hidden;
}

.cardback {
  position: absolute;
  height: 100%;
  padding: 2rem;
  background: #eee;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0 1rem rgba(51, 51, 51, 0.1),
    0 0 1rem rgba(51, 51, 51, 0.1);
  box-shadow: 0 0 1rem rgba(51, 51, 51, 0.1), 0 0 1rem rgba(51, 51, 51, 0.1);
  overflow: hidden;
  visibility: hidden;

  opacity: 0;
}

.cardbackcontent {
  max-height: 100%;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column;
  flex-direction: column;
  height: inherit;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1rem;
}

@-webkit-keyframes translate {
  0% {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes translate {
  0% {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .cardswrapper {
    grid-template-columns: auto;
    width: auto;
    justify-content: center;
  }
  .card {
    max-width: 600px;
  }
}
@media (max-width: 568px) {
  .card {
    max-width: 280px;
  }
}

/* new */
.cardfront {
  height: 100%;
}

.a1 {
  text-decoration: none;
  color: black;
  letter-spacing: 0.1em;
  padding: 5px 5px;
  position: relative;
}
.a1:hover {
  color: black;
}
.a1:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  height: 2px;
  position: absolute;
  background: #f1c40f;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.a1:hover:after {
  width: 100px;
  left: 0;
}
