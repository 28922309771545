.btn:before,
.btn:after {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  background: transparent;
}
.text-center {
  display: none;
}
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: black;
}
.certif-container {
  display: flex;
  flex-direction: column;
  margin-top: 11vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.admin-title {
  width: 100%;
  text-align: center;
  font-size: 2.25rem;
  margin-bottom: 1.5rem;
  color: #f1c40f;
}

.custom-file {
  width: 80vw;
}
.custom-file button {
  background-color: #04aa04;
  color: green;
}
.custom-file-input {
  display: flex;
  height: 5vh;
  width: 50%;
  font-size: 1.1rem;
  justify-content: center;
  background-color: #f0f8ff21;
  border: 2px solid #34353521;
  color: whitesmoke;
  border-radius: 0.5rem;
  margin: auto auto;
  padding-top: 0.25rem;
  margin-top: 2rem;
}
.custom-file-input:hover {
  cursor: pointer;
}
.custom-file-label {
  width: 50%;
}
.progress {
  background-color: #f0f8ff21;
  border-radius: 0.5rem;
  width: 50%;
  margin: auto auto;
  color: white;
}
.progress-bar {
  background-color: #04aa04;
  border-radius: 0.5rem;
}
.upload-button {
  margin-top: 2rem;
  width: 100%;
  height: 5vh;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  background-color: #008aff;
}
.upload-button:hover {
  cursor: pointer;
}
.fileuploadbutton {
  height: 20vh;
  width: 80%;
  margin: auto auto;
}
.div-shrink {
  width: 80%;
}