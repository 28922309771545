.skeleton {
    /* background-color: #ddd; */
    
    animation: skeleton-loading 1s ease-in-out infinite alternate;
    margin: 10px 0;
    border-radius: 10px;
}

@keyframes skeleton-loading {
    0% {
        background-color: #15202B;
        opacity: 0.9;
        transform: translateY(6px) scale(0.98);
    }
    100% {
        background-color: #15202B;
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

.skeleton.text {
    width: 100%;
    height: 12px;
}

.skeleton.title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
}

.skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.skeleton.thumbnail {
    width: 75rem;
    height: 17rem;
    margin: 2rem 0;
}

@media screen and (max-width: 768px){
       .skeleton.thumbnail {
        width: 100%;
        height: 17rem;
        margin: 2rem 0;
    }
     }