.services-submenu {
  width: 21rem;
  max-width: 21rem;
  position: absolute;
  top: 100%;
  left: -15%;
  list-style: none;
  text-align: start;
}

.services-submenu li {
  background: black;
  cursor: pointer;
}

.services-submenu li a:hover {
  background: black;
}

.services-submenu.clicked {
  display: none;
}

.submenu-item {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 16px;
}

@media (max-width: 768px) {
  .services-submenu {
    z-index: 100;
  }
}