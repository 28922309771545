.dropdownSelector {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: -1.5rem;
  margin-bottom: 2rem;
}

.selector {
  border: none;
  background: #eee;
  color: black;
  font-size: 1.2rem;
  padding: 1rem 2.8rem 1rem 1rem;
  border-radius: 5px;
  background-color: #ddd;
  max-width: 60%;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expandMore {
  color: #000;
  position: absolute;
  top: 30%;
  right: 22%;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .expandMore {
    top: 25%;
  }
}

@media screen and (max-width: 576px) {
  .expandMore {
    top: 18%;
  }
}

@media screen and (max-width: 400px) {
  .selector {
    font-size: 1rem;
  }

  .expandMore {
    top: 15%;
  }
}
