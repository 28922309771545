.mainContainer {
  margin-top: 11vh;
}

h1 {
  text-align: center;
  font-size: 2.25rem;
  margin-bottom: 2rem;
  letter-spacing: 2px;
  color: #f1c40f;
}

h1.committeeHeading {
  color: white;
}

section {
  width: 90%;
  max-width: 1200px;
  margin: 3rem auto;
}

section h2 {
  text-align: center;
  /* font-size: 2rem;  PAHLE CLASSNAME DEFINE KAR LE, TAB CSS DEFINE KAR...BAHUT JAGAH CHANGE HO JA RHI HAI */
  /* margin-bottom: 2rem;
  letter-spacing: 2px; */
  /* color: #f1c40f; */
}

.cmtCard1 {
  display: flex;
  flex-wrap: wrap;
}

.leftCard {
  width: 50%;
}

.leftCard2 {
  width: 50%;
  color: rgb(194, 194, 194);
  padding: 5% 5% 5% 5%;
  /* text-align: justify; */
  letter-spacing: 1.5px;
  font-size: 18px;
}

.leftCard .img {
  width: 430px;
  padding: 0 2% 0 8%;
}

.cardImg {
  align-content: center;
  width: 480px;
  padding: 0 2% 0 8%;
}

.cardImg2 {
  align-content: center;
  width: 600px;
  padding: 1% 1% 0 2%;
}

.cardImg3 {
  align-content: center;
  width: 580px;
  height: auto;
  padding: 1% 4% 0 2%;
}

.rightCard {
  width: 50%;
  color: rgb(194, 194, 194);
  padding: 0% 5% 5% 5%;
  /* text-align: justify; */
  letter-spacing: 1.5px;
  font-size: 18px;
}

.centerCard {
  letter-spacing: 1.5px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 125%;
  padding: 0rem 2rem;
}

.btn {
  background-color: rgb(235, 235, 235);
  color: black;
  margin: 2px 6px;
  padding: 5px 6px;
  border: 1px solid #f1c40f;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  font-size: 0.8rem;
  display: inline-block;
}

.btn:hover {
  background-color: #f1c40f;
  color: white;
  transition: all 0.2s ease-in-out;
}

.hrLine {
  width: 75%;
  color: grey;
}

.head2 {
  color: #f1c40f;
  letter-spacing: 2px;
  margin: 3% 0;
  font-size: 1.8rem;
}

section .description {
  letter-spacing: 1.5px;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 125%;
  padding: 0rem 2rem;
}

.imgContainer {
  height: 100%;
  width: 90%;
  border-radius: 1rem;
  margin: 0rem auto 2rem;
}

.imgContainer img {
  /* max-height: 100vh; */
  border-radius: 1.25rem;
  object-fit: cover;
  object-position: top;
  width: 500px;
}

.imgContainer3 img {
  /* max-height: 100vh; */
  border-radius: 1.25rem;
  object-fit: cover;
  object-position: top;
  width: 700px;
}

.img2Container img {
  max-height: auto;
  border-radius: 1.25rem;
  object-fit: cover;
  object-position: top;
  width: 500px;
}

/* Welcome Section */
.welcomeSection {
  position: relative;
  height: 60vh;
}

.backgroundImage {
  background-image: url("../../../images/tsg.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(0.1rem);
}

.welcomeContent {
  position: absolute;
  top: 0px;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.welcomeContent h1 {
  font-size: 3rem;
  font-weight: bolder;
}

@media screen and (max-width: 900px) {
  .img2Container img {
    max-height: auto;
    border-radius: 1.25rem;
    object-fit: cover;
    object-position: top;
    width: 450px;
  }

  .imgContainer img {
    /* max-height: 100vh; */
    border-radius: 1.25rem;
    object-fit: cover;
    object-position: top;
    width: 450px;
  }

  .cmtCard1 {
    display: flex;
    flex-wrap: wrap;
  }

  .leftCard {
    width: 50%;
  }

  .leftCard2 {
    width: 50%;
    color: rgb(194, 194, 194);
    padding: 5% 5% 5% 5%;
    /* text-align: justify; */
    letter-spacing: 1.5px;
    font-size: 18px;
  }

  .leftCard .img {
    width: 430px;
    padding: 0 2% 0 8%;
  }

  .cardImg {
    align-content: center;
    width: 350px;
    padding: 0 2% 0 8%;
  }

  .cardImg2 {
    align-content: center;
    width: 350px;
    padding: 1% 1% 0 2%;
  }

  .rightCard {
    width: 50%;
    color: rgb(194, 194, 194);
    padding: 0% 2% 2% 2%;
    /* text-align: justify; */
    letter-spacing: 1.5px;
    font-size: 14px;
  }

  .centerCard {
    letter-spacing: 1.5px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 125%;
    padding: 0rem 2rem;
  }


  .btn {
    background-color: rgb(235, 235, 235);
    color: black !important;
    margin: 1px;
    padding: 6px 5px 5px 5px;
    border: none;
    border-radius: 3px;
  }

  .btn:hover {
    background-color: rgb(255, 46, 46);
    transition: 30ms;
    color: white !important;
    /* transform: scale(1.1); */
  }

  .hrLine {
    width: 75%;
    color: grey;
  }

  .head2 {
    color: #f1c40f;
    letter-spacing: 2px;
    margin: 3% 0 -1% 0;
  }
}

@media screen and (max-width: 464px) {
  .img2Container img {
    max-height: auto;
    border-radius: 1.25rem;
    object-fit: cover;
    object-position: top;
    width: 280px;
  }

  .imgContainer img {
    /* max-height: 100vh; */
    border-radius: 1.25rem;
    object-fit: cover;
    object-position: top;
    width: 280px;
  }

  .imgContainer3 img {
    /* max-height: 100vh; */
    border-radius: 1.25rem;
    object-fit: cover;
    object-position: top;
    width: 390px;
  }

  .cmtCard1 {
    display: flex;
    flex-wrap: wrap;
  }

  .leftCard {
    width: 90%;
  }

  .leftCard2 {
    width: 90%;
    color: rgb(194, 194, 194);
    padding: 5% 5% 5% 5%;
    /* text-align: justify; */
    letter-spacing: 1.5px;
    font-size: 18px;
  }

  .leftCard .img {
    width: 430px;
    padding: 0 2% 0 8%;
  }

  .cardImg {
    align-content: center;
    width: 350px;
    padding: 0 2% 0 8%;
  }

  .cardImg2 {
    align-content: center;
    width: 350px;
    padding: 0 1% 0 2%;
  }

  .cardImg3 {
    align-content: center;
    width: 330px;
    height: auto;
    padding: 1% 4% 0 2%;
  }

  .rightCard {
    width: 97%;
    color: rgb(194, 194, 194);
    padding: 5% 2% 5% 5%;
    /* text-align: justify; */
    letter-spacing: 1.5px;
    font-size: 14px;
  }

  .btn {
    background-color: rgb(235, 235, 235);
    color: black !important;
    margin: 1px;
    padding: 6px 5px 5px 5px;
    border: none;
    border-radius: 3px;
  }

  .btn:hover {
    background-color: rgb(255, 46, 46);
    transition: 30ms;
    color: white !important;
    /* transform: scale(1.1); */
  }

  .hrLine {
    width: 75%;
    color: grey;
  }

  .head2 {
    color: #f1c40f;
    letter-spacing: 2px;
    margin: 5% 0 -4% 0;
  }
}