.cardParentDiv{
  display: grid;
  max-width: 1200px;
  margin: 0 auto;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding:8px 12px;
  box-sizing: border-box;
}

.cardContainer {
  background-color: rgb(14, 15, 18);
  display: flex;
  gap: 16px;
  row-gap: 20px;
  border: 2px solid rgb(77, 65, 17);
  border-radius: 12px;
  height: 160px;
  transition: transform 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  overflow: hidden;
}
.cardContainer:hover{
  border-color: rgb(185, 157, 43);
  transform: scale(1.05);

}
.imageCardDiv{
  width:132px;
  flex-shrink: 0;
}
.image{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.cardDiv{
  display:flex;
  flex-direction:column;
  justify-content:center;
  padding-right:12px;
}

.cardHeading{
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 8px;
  max-width: 100%;
  text-align: left;

}
.link {
  text-decoration: none;
  color: white; 
  font-size: 24px;
  transition: color 0.3s ease; 
}

.link:hover {
  color: rgb(185, 157, 43); 
}

.cardDiv2{
  display: flex;
  align-items:center;
  gap:8px;
  padding: 8px 0;
}
.btnHandler{
  padding: 0.75rem 1.5rem;
  border: 2px solid white;
  border-radius: 30px;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btnHandler:hover {
  background-color: white;
  color: black;
}

@media (max-width: 880px) {

  .cardParentDiv{
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
 
}
@media (max-width: 560px) {
  .cardHeading {
    font-size: 1rem;
  }
  .cardParentDiv{
    display: grid;
    grid-template-columns: repeat(2, minmax(260px, 1fr));
  }
 
}

@media (max-width: 480px) {
  .cardParentDiv{
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
  .cardHeading {
    font-size: 1rem;
  }

  .btnHandler {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}



