@font-face {
    font-family: "FontAwesome";
    font-weight: normal;
    font-display: auto;
    font-style: normal;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2")
    format("woff2");
}
.modal {
    position : fixed; 
    left : 0; 
    top : 0; 
    right : 0; 
    bottom : 0;
    margin: 0;
    padding: 20% 0; 
    background-image: radial-gradient(circle at center, white, #888);
    /* background-color : rgba(0, 0, 0, 0.5);  */
    display : flex; 
    align-items : center; 
    justify-content : center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.modal.show {
    opacity: 1;
    pointer-events: visible;
}

.modal.hide {
    display: none;
}

.modal-content { 
    width : 30rem; 
    background-color: #ffffff;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
    margin-top: 0rem;
    margin-bottom: -15rem;
} 

.modal.show .modal-content{
    transform: translateY(0);
}

.modal-header, .modal-footer {
    padding : 10px; 
} 
.modal-title { 
    margin : 0; 
    color: #000;
    text-align: center;
    line-height: 1;
    font-family: Poppins, sans-serif;
    font-size: 1.7rem;
} 
.modal-body { 
    padding : 10px; 
    border-top : 1px solid #eeeeee;
    border-bottom : 1px solid #eeeeee; 
}
.modal-date {
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #918a8a;
    margin-top: 5px;
    font-family: Poppins, sans-serif;
}
.poster-img {
    /* max-width: 100%; */
    /* max-height: 100%; */
    /* display: block; */
}

