.contactsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  font-size: 20px;
}
h1 {
  color: white;
}
.row1 {
  width: 150%;
  border: 1px soid black;
  background-color: white;
}
.col1p {
  width: 50%;
  border: 1px solid black;
  text-align: left;
  border-right: none;
  padding: 0 10% 0 2%;
  font-size: 20px;
}

.col2p {
  width: 20em;
  border: 1px solid black;
  text-align: left;
  padding: 0 2% 0 2%;
  font-size: 20px;
}
/* .row1 :hover {
  background-color: whitesmoke;
} */
.contacts_content__1wT_L {
  padding: 2 rem;
  margin-right: 0.05 rem;
  max-height: 90 vh;
  overflow-y: scroll;
  width: 100% !important;
}
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.container {
  background-color: black;
  width: 300px;
  height: 350px;
  border-radius: 0.5rem;
  box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}
.imgbox {
  transition: 0.5s;
  height: 80%;
  overflow: hidden;
}
img {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  object-fit: cover;
}

.sociallinks {
  height: auto;
  position: absolute;
  top: 60%;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.sociallinks li {
  list-style: none;
}
.sociallinks li a {
  color: black;
  display: block;
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  font-size: 1.75rem;
  border-radius: 50%;
  pointer-events: none;
  cursor: default;
  transform: translateY(140%);
  transition: 0.4s ease;
  opacity: 0;
}

.details {
  padding: 0rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2rem);
  box-shadow: 0 1rem 1rem rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(2rem);
  height: 20%;
  align-items: center;
}
.details h2 {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}
.details span {
  color: rgb(86, 38, 139);
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}
.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.card1 {
  color: white;
  text-align: center;
  font-size: 21px;
  width: 35%;
  box-shadow: 0 0 10px white;
  margin: 3%;
  padding: 2%;
  justify-content: space-evenly;
  border-radius: 10px;
}
.senateCard {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
  margin-left: 5%;
}
.shead1 {
  font-size: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3%;
  align-items: center;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .cards {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .cards > div {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 464px) {
  .card1 {
    font-size: 11px;
    margin: 3% 1% 3% 1%;
    width: 48%;
    box-shadow: 0 0 3px white;
  }
  .senateCard {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-left: 0%;
  }
  .shead1 {
    font-size: 24px;
  }
}
