.highlightCard {
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2);
    padding: 20px 80px;
    text-align: center;
    border-radius: 10px;
    margin: 10px auto;
}

.topContainer .date {
    text-align: center;
    font-size: 16px;
}

.scoreContainer {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}

.scoreContainer .upcomingTeamContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.scoreContainer .teamContainer .teamName {
    font-size: 34px;
    font-weight: bold;
}

.scoreContainer .teamContainer .overs {
    font-size: 16px;
}

.scoreContainer .vs {
    padding: 30px;
}

.bottomContainer .description,
.topContainer .description {
    font-size: 16px;
}

.bottomContainer .bestPlayer {
    border: 1px solid gold;
    padding: 20px;
    margin: 10px;
}

.bottomContainer .bestPlayer .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
}

.bottomContainer .bestPlayer .bestPlayerName {
    font-size: 16px;
}

.bottomContainer .bestPlayer .awardIcon {
    color: gold;
    margin: 0 5px;
}

.bottomContainer .scorecardBtn {
    padding: 10px;
    border: 0;
    border-radius: 5px;
    color: #fff;
    background-color: aquamarine;
    cursor: pointer;
    transition: all 0.2s ease;
}

.bottomContainer .scorecardBtn:hover {
    background-color: rgb(82, 204, 163);
    transition: all 0.2s ease;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 540px) {
    .highlightCard {
        padding: 2rem;
    }

    .scoreContainer {
        font-size: 3rem;
    }

    * .teamContainer {
        font-size: 3rem;
    }

    .teamName {
        font-size: 3rem;
    }

    .bestPlayerName {
        font-size: 1.5rem;
        box-sizing: border-box;
    }

    .bottomContainer .bestPlayer {
        border: 1px solid gold;
        padding: 1rem;
        margin: 0;
    }

    .scorecardBtn {
        margin: 1rem;
    }
}