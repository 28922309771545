* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

footer {
  width: 100%;
  background-color: black;
  margin: 0;
  padding: 0;
}

.footerContainer {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  height: 27.5vh;
  padding-bottom: 1rem;
}

.footerLine{
  width: 70%;
  background-color: transparent;
  border-bottom: 1px solid white;
  margin: 2rem auto;
}

.tsgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 32.5%;
  border-right: 3px dashed grey;
}
.imgContainer {
  height: 40%;
  margin-bottom: 0.5rem;
}
.imgContainer img {
  object-fit: cover;
}
.tsgContainer .tsgDescription {
  color: white;
}
.tsgContainer .tsgDescription .tsgHeading {
  font-size: 1.25rem;
}
.tsgContainer .tsgDescription .address {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.iconsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0rem 20%;
  justify-content: space-around;
}
.iconsContainer a {
  color: #fff;
  font-size: 1.5rem;
  margin: 0.25rem;
}
.iconsContainer a:hover {
  transition: ease-out 0.5s;
  color: #f1c40f;
}
.linksContainer {
  flex-grow: 2;
  padding: 0rem;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-around;
  border-right: 3px dashed grey;
}

.linksContainer p{
  text-align: center;
  border-bottom: 1.5px dotted grey;
  padding: 0.5rem 0rem;
  margin-bottom: 0rem;
  position: relative;
}

.linksContainer p span{
  visibility: hidden;
  width: 7.5rem;
  background-color: grey;
  color: #fff;
  font-size: 0.75rem;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 85%;
}

.linksContainer p:hover span{
  visibility: visible;
}

.linksContainer p a {
  padding: 0;
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
}
.linksContainer p a:hover {
  color: #f1c40f;
  font-weight: 500;
}
.contactSection {
  padding-left: 3rem;
  width: 32.5%;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 700;
}
.contactSection span:first-of-type {
  font-size: 2.5rem;
  color: #fff;
}
.contactSection span:last-of-type {
  font-size: 2rem;
  color: #f1c40f;
  margin-bottom: 1rem;
}
.contactButton {
  width: 50%;
  color: white;
  transition: 0.2s;
  font-size: 1rem;
  max-width: 100%;
  padding: 1.25rem 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #f1c40f;
  color: #fff;
  background-image: linear-gradient(45deg, #f1c40f 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  transition: background 300ms ease-in-out;
}
.contactButton:hover {
  background-position: 0;
}

.copyright{
  text-align: center;
  color: white;
  margin: 1rem auto;
  padding-top: 20px;
  padding-bottom: 15px;
}

.copyright a{
  color: #f1c40f;
}

.copyright a:hover{
  color: #f6d965;
}

@media screen and (min-width: 1460px) {
  .linksContainer p{
    font-size: 1.25rem;
  }
}

@media screen and (max-width : 768px){
  .imgContainer {
    display: none;
  }
}

@media screen and (max-width: 1400px) { 
  .contactSection {
    padding-left: 0;
  }

  .footerContainer {
    height: auto ;
    flex-direction: column;
  }
  .tsgContainer {
    width: 100%;
    border-right: none;
    border-bottom: 3px dashed grey;
  }
  .tsgContainer .tsgDescription .tsgHeading {
    text-align: center;
    font-size: 2rem;
  }
  .tsgContainer .tsgDescription .address {
    font-size: 1.25rem;
  }
  .iconsContainer {
    width: 60%;
    margin-bottom: 2rem;
  }
  .iconsContainer a {
    font-size: 1.5rem;
  }
  .linksContainer {
    border-bottom: 3px dashed grey;
    margin: 0 auto;
    padding: 1.5rem 0;
    border-right: none;
    width: 100%;
  }

  .linksContainer p a {
    padding: 0;
    text-transform: capitalize;
    font-size: 1rem;
  }
  .contactSection {
    margin-top: 1rem;
    width: 100%;
    flex-direction: column;
    text-align: center;
  }
  .contactButton {
    width: 40%;
    margin: auto;
  }
  .contactSection span:last-of-type {
    margin-bottom: 1rem;
  }
  .contactSection button {
    margin-bottom: 1rem;
  }
}


