.skeletonCard {
  width: 300px;
  height: 120px;
  background-color: #1d1c1c;
  border-radius: 8px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skeletonImage {
  width: 80%;
  height: 60%;
  background-color: #383838;
  border-radius: 4px;
  margin-bottom: 8px;
}

.skeletonText {
  width: 60%;
  height: 12px;
  background-color: #3d3d3d;
  border-radius: 4px;
}