@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.container {
  background-color: black;
  width: 300px;
  height: 350px;
  border-radius: 0.5rem;
  box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  margin: 1rem 1rem;
}
.imgbox {
  transition: 0.5s;
  height: 80%;
  overflow: hidden;
}
img,
.svgContainer {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  object-fit: cover;
}

.container:hover .imgbox img,
.container:hover .imgbox .svgContainer {
  opacity: 0.4;
  transform: scale(1.1);
}
.sociallinks {
  height: auto;
  position: absolute;
  top: 60%;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.sociallinks li {
  list-style: none;
}
.sociallinks li a {
  color: black;
  display: block;
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  font-size: 1.75rem;
  border-radius: 50%;
  pointer-events: none;
  cursor: default;
  transform: translateY(140%);
  transition: 0.4s ease;
  opacity: 0;
}
.container:hover .sociallinks li a {
  transform: translateY(25%);
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}
.container .sociallinks li a:hover i {
  transition: 0.5s ease-in-out;
  color: rgb(86, 38, 139);
  /* transform: rotateY(360deg); */
}
.container:hover .sociallinks li:nth-child(1) a {
  transition-delay: 0s;
}

.details {
  padding: 0rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2rem);
  box-shadow: 0 1rem 1rem rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(2rem);
  height: 20%;
  align-items: center;
  text-align: center;
}
.details h2 {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}
.details span {
  color: rgb(86, 38, 139);
  font-size: 1.25rem;
  font-weight: 600;
}

@media screen and (max-width: 552px) {
  .container {
    width: 250px;
    height: 300px;
  }
}

.svgContainer {
  padding: 10px;
}
