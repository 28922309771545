.dashboard-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    margin-top: 2rem;
}


.profile-pic {
    position:relative;
    top: 1rem;
    text-align: center;
}
.admin-pic {
    height: 100px;
    width: 100px;
    border-radius: 50px;
}
.admin-info {
    background: rgba(197,190,159,.23137254901960785);
    padding: 1.5rem;
    color: #ffffff;
    border-radius: 20px;
}
.adm-name-conti {
    text-align: center;
}
.admin-name {
    margin-top: 1rem;margin-top: 1rem;
    color: #f1c40f;
    width: 300px;
    height: auto;
    
    width: 300px;
    height: auto;
    
}
.admin-desc {
    display: flex;
    flex-direction: column;

}
.admin-post  {
    padding: 1rem;
    margin-bottom: -0.5rem;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* border: 2px solid #f1c40f ;     */
}
.post-refine {
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: -0.5rem;
    text-align: center;
    
}
.router-btns {
    background: rgba(197,190,159,.23137254901960785);
    padding: 2rem;
    color: #ffffff;
    border-radius: 20px;
    margin: 2rem;
}
.routing-conti {
    text-align: center;
    padding: 1rem 5rem;
}
.btn-route {
    /* color: #f1c40f; */
    /* color: #ffffff; */
    /* background: transparent; */
    opacity: 1;
    border-width: 0;
    border-radius: 10px;
    padding: 1rem;
    /* background-color: rgba(197,190,200,.23137254901960785); */
    background-color: #24a0ed;
    width: 15rem;
    font-size: 20px;
}