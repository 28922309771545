body {
  overflow-x: hidden;
}
.contactsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.maincontainer {
  /*display: flex;*/
  /*max-height: 120vh;*/
  width: 100%;
  margin-top: 1.5rem;
}

.postHeading {
  font-size: 2rem;
  text-align: center;
  margin: auto;
  color: #f1c40f;
}
.multipleCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.content::-webkit-scrollbar {
  display: none;
}
.multipleCards > div {
  margin: 1.5rem 1rem;
}
.contactsCircle1 {
  top: 5%;
  right: -2%;
  background-color: white;
  background: linear-gradient(45deg, #fc466b, #f1c40f);
  height: 18rem;
  width: 18rem;
  position: absolute;
  border-radius: 10rem;
  z-index: -1;
}
.contactsCircle2 {
  bottom: 0%;
  left: 5%;
  background-color: white;
  background: linear-gradient(45deg, #fc466b, #f1c40f);
  height: 18rem;
  width: 18rem;
  position: absolute;
  border-radius: 10rem;
  z-index: -1;
}
.contactsCircle3 {
  top: 15%;
  left: 30%;
  background-color: white;
  background: linear-gradient(45deg, #fc466b, #f1c40f);
  height: 15rem;
  width: 15rem;
  position: absolute;
  border-radius: 10rem;
  z-index: -1;
}
.contactsCircle4 {
  bottom: 5%;
  right: 30%;
  background-color: white;
  background: linear-gradient(45deg, #fc466b, #f1c40f);
  height: 10rem;
  width: 10rem;
  position: absolute;
  border-radius: 10rem;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .maincontainer {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
}
