* {
  box-sizing: border-box;
}
body {
  background: black;
}
/*Font Awesome Fonts*/
@font-face {
  font-family: "FontAwesome";
  font-weight: normal;
  font-display: auto;
  font-style: normal;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2")
    format("woff2");
}
.blogCard {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: 0;
  width: 100%;
}
.blogCard a {
  color: inherit;
}
.blogCard a:hover {
  color: #e4b601;
}
.blogCard:hover .photo {
  transform: scale(1.3) rotate(3deg);
}
.blogCard .meta {
  position: relative;
  z-index: 0;
  height: 120px;
}
.blogCard .photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
}
.blogCard .details,
.blogCard .details ul {
  margin: auto;
  padding: 0;
  list-style: none;
}
.blogCard .details {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  margin: auto;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  width: 100%;
  font-size: 0.9rem;
}
.blogCard .details a {
  text-decoration: none;
}
.blogCard .details ul li {
  display: inline-block;
}
.blogCard .details .date:before {
  font-family: FontAwesome;
  margin-right: 10px;
  content: "\f133";
}
.blogCard .details .tags ul:before {
  font-family: FontAwesome;
  content: "\f02b";
  margin-right: 10px;
}
.blogCard .details .tags li {
  margin-right: 2px;
}
.blogCard .details .tags li:first-child {
  margin-left: -4px;
}
.blogCard .description {
  padding: 2rem;
  background: rgb(255, 255, 255);
  position: relative;
  flex-grow: 1;
  z-index: 1;
}
.blogCard .description h1,
.blogCard .description h2 {
  font-family: Poppins, sans-serif;
}
.blogCard .description h1 {
  color: black;
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
}
.blogCard .description h2 {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #918a8a;
  margin-top: 5px;
}
.blogCard .description .readMore {
  text-align: right;
}
.blogCard .description .readMore button{
  /* color: #e4b601; */
  position: relative;
  top: 1vw;
  text-decoration: none;
  /* border: 2px solid #e4b601; */
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  margin: 0 0.5rem;
}
.blogCard .description .readMore button:after {
  content: "\f061";
  font-family: FontAwesome;
  margin-left: -10px;
  opacity: 0;
  vertical-align: middle;
  transition: margin 0.3s, opacity 0.3s;
}
.blogCard .description .readMore button:hover:after {
  margin-left: 5px;
  opacity: 1;
}
.blogCard p {
  position: relative;
  margin: 1rem 0 0;
  color: black;
}
.blogCard p:first-of-type {
  margin-top: 1.25rem;
  white-space: pre-wrap;
}
.blogCard p:first-of-type:before {
  content: "";
  position: absolute;
  height: 5px;
  background: #f1c40f;
  width: 35px;
  top: -0.75rem;
  border-radius: 3px;
}
.blogCard:hover .details {
  left: 0%;
}

.btnsContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.disable{
  display: none;
}

@media (min-width: 768px) {
  .blogCard {
    flex-direction: row;
    width: 100%;
  }
  .blogCard .meta {
    flex-basis: 40%;
    height: auto;
  }
  .blogCard .description {
    flex-basis: 60%;
    padding: 50px;
  }
  .blogCard .description:before {
    transform: skewX(-3deg);
    content: "";
    background: #fff;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  .blogCard.alt {
    flex-direction: row-reverse;
  }
  .blogCard.alt .description:before {
    left: inherit;
    right: -10px;
    transform: skew(3deg);
  }
  .blogCard .details {
    padding: 30px 45px;
  }
}
