::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 0.4rem;

  z-index: 5555;
}
::-webkit-scrollbar-thumb {
  border-radius: 0.3rem;
  background-color: #949494;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #b9b9b9;
}
body,
body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.thumbnails img {
  width: 10% !important;
}
.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.active {
  background-color: transparent !important;
}

.activeSide {
  background-color: blue;
}
.contacts_content__1wT_L {
  padding: 2 rem;
  margin-right: 0.05 rem;
  max-height: 90 vh;
  overflow-y: scroll;
  width: 100% !important;
}
/* .main-container {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
} */

section {
  max-width: 1500px;
  align-self: center;
}

.pageHeading {
  margin-top: 5rem;
  margin-bottom: 4rem;
  font-size: 2.5rem;
  text-align: center;
  color: #fff;
}

.sectionHeading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  color: #fff;
}

section .section-heading {
  font-size: 34px;
  margin: 20px 0;
  text-align: center;
}

section .description {
  font-size: 18px;
}

section blockquote {
  margin: 15px 0;
  padding: 10px;
  border: 0;
  border-left: 4px;
  border-style: solid;
  border-color: rgb(117, 117, 117);
}
@media screen and (max-width: 1200px) {
  html {
    font-size: 16px;
  }
}
@media screen and (max-width: 960px) {
  html {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-width: 576px) {
  html {
    font-size: 10px;
  }
}
