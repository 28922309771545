/* .notification {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.3s ease;
} */

.notification:hover {
  transform: scale(1.02);
  transition: all 0.3s ease-in-out;
}

.notification .title {
  font-size: 1.1rem;
  font-weight: 400;
}

.notification .closeIcon {
  position: absolute;
  right: 3%;
  top: 15%;
  font-size: 1rem;
  color: black;
  cursor: pointer;
}

.notification .createdTime {
  font-size: 0.75rem;
  text-align: right;
  color: rgba(0, 0, 0, 0.4);
}

.notification .description {
  color: rgb(43, 43, 43);
  padding: 0.4rem 0;
  font-size: 0.8rem;
  text-overflow: ellipsis;
  line-height: 1.2rem;
}