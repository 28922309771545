.withTable {
  margin: auto;
  padding: 0;
}
.withTable .table-container {
  font-size: 1.2rem;
}
.withTable .table-container table {
  margin-bottom: 1rem;
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 10px rgba(44, 52, 60, 0.6);
}
.withTable .table-container th,
.withTable .table-container td {
  padding: 1rem;
  padding-right: 0.25rem;
  color: #000;
}
.withTable .table-container th .medal::before,
.withTable .table-container td .medal::before {
  content: " \25CF";
  font-size: 30px;
}
.withTable .table-container th .gold,
.withTable .table-container td .gold {
  color: gold;
}
.withTable .table-container th .silver,
.withTable .table-container td .silver {
  color: silver;
}
.withTable .table-container th .bronze,
.withTable .table-container td .bronze {
  color: #cd7f32;
}
.withTable .table-container th {
  text-align: left;
  color: #fff;
}
.withTable .table-container thead {
  position: sticky;
  top: 1;
}
.withTable .table-container thead th {
  background-color: #e7bd12;
}
.withTable .table-container tbody {
  margin-top: 2rem;
}
.withTable .table-container tbody tr {
  background-color: #fff;
}
.withTable .table-container tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
.withTable .table-container tbody tr:last-of-type {
  border-bottom: 2px solid #04d6ac;
}
.withTable .table-container tbody #overall:first-of-type {
  background-color: hsl(168, 76%, 80%);
}
.withTable .table-container tbody td {
  position: relative;
}
.withTable .table-container tbody td ul {
  list-style-type: none;
}