.backgroundImage {
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../images/iitkgp.jpg");
  background-size: cover;
  background-position: center;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}


.form-container {
  background: #ebebeb; 
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 500px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Poppins', sans-serif;
  color: #444; 
}


.form-container:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}


.form-container h1 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
  font-weight: 600;
  color: #2c3e50;
  letter-spacing: 1px;
}


.form-container label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #000000;
  font-weight: 500;
}

/* Inputs and textareas */
.form-container input,
.form-container textarea {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 15px;
  background: #f9f9f9;
  color: #000000;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}


.form-container input:focus,
.form-container textarea:focus {
  border-color: #007bff;
  box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.1);
  outline: none;
}


.form-container input::placeholder,
.form-container textarea::placeholder {
  font-size: 14px;
  color: #aaa;
}


.form-container textarea {
  resize: none;
  height: 120px;
}


.form-container button {
  width: 100%;
  padding: 12px;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}


.form-container button:hover {
  background: linear-gradient(135deg, #0056b3, #003d80);
}


.form-container button:active {
  transform: scale(0.98);
}


.form-container button:disabled {
  background: #f0eaea;
  cursor: not-allowed;
}


.form-container button.loading {
  position: relative;
  color: transparent;
  cursor: wait;
}

.form-container button.loading:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 3px solid #ffffff;
  border-top: 3px solid #0056b3;
  border-radius: 50%;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


@media (max-width: 768px) {
  .form-container {
    padding: 30px;
  }

  .form-container h1 {
    font-size: 24px;
  }

  .form-container input,
  .form-container textarea {
    font-size: 14px;
  }

  .form-container button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .form-container {
    padding: 20px;
  }

  .form-container h1 {
    font-size: 20px;
  }

  .form-container input,
  .form-container textarea {
    font-size: 13px;
  }

  .form-container button {
    font-size: 13px;
    padding: 10px;
  }
}
