.login-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-screen__form {
    width: 30vw;
    padding: 1.5rem;
    background: #c5be9f3b;
    border-radius: 20px;
  }
  
  .login-screen__form:hover {
    box-shadow: 4px 4px 20px #fff1766b, -4px -4px 20px #fff1766b;
  }
  
  .login-screen__title {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    color: #f1c40f;
  }
  
  .login-screen__forgotpassword {
    font-size: 0.7rem;
  }
  
  .login-screen__subtext {
    font-size: 0.7rem;
    display: block;
    margin: 0.5rem 0;
  }
  
  .form-group {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }
  
  input {
    padding: 10px;
    border-radius: 8px;
    border-width: 0;
    width: 90%;
    background: #ecf0f3;
    font-size: 1rem;
  }
  
  .form-button {
    display: flex;
    justify-content: center;
  }
  
  .form-button button {
    background-color: blue;
    border-width: 0;
    color: #ecf0f3;
    font-size: 1rem;
    padding: 0.5rem;
    margin-top: 0.4rem;
    border-radius: 0.5rem;
    width: 20%;
  }
  
  .form-button button:hover {
    cursor: pointer;
    box-shadow: 4px 4px 2px #21201b;
    background-color: #5f5fc2;
    color: #0000ff;
    font-weight: bold;
  
  }
  .error-message{
    display: flex;
    justify-content: left;
    margin: 1rem;
    color: red;
  }